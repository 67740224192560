import React, { Fragment, useState } from "react";
import SecurityQuestionCheck from "./SecurityQuestionCheck";
import { Button, Form } from "react-bootstrap";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import background from "../../assets/images/background-white.png";
import { formCheck, generateVerification } from "../../functions/encoding";
import axios from "axios";
import { getAuth, deleteUser } from "firebase/auth";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordWrapper from "../PasswordWrapper";
import { postWithSecureCredentials } from "../../functions/securePost";

library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RemoveAccount(props) {
  const [formValue, setFormValue] = useState({
    answers: Array(5).fill(""),
    password: "",
    code: "",
  });

  const onChangeAnswer = (index, value) => {
    let newAnswer = formValue.answers;
    newAnswer[index] = value;
    setFormValue({ ...formValue, answers: newAnswer });
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    let data = {
      passCheck: formCheck(formValue.password + props.user.id),
      answersCheck: generateVerification(formValue.answers.join("")),
      userId: props.user.id,
    };

    try {
      await props.msftReauthPopup();

      postWithSecureCredentials(SERVER_URL + "removeAccount", data)
        .then(async (res) => {
          try {
            let user = getAuth().currentUser;
            await deleteUser(user);
            props.signOut();
            alert("Your account has been removed.");
            window.location.replace("/home");
          } catch (e) {
            alert("Oops, something went wrong...");
            props.signOut();
          }
        })
        .catch((error) => {
          if (error.response.data.errormsg === "Wrong Answers") {
            alert("Please recheck your questions");
          }
        });
    } catch (e) {
      alert("Reauthentication Failed. Please try again.");
    }
  };

  const deleteBody = (
    <Fragment>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
        onSubmit={handleCodeSubmit}
      >
        <SecurityQuestionCheck
          answers={formValue.answers}
          onChangeAnswer={onChangeAnswer}
          user={props.user}
        />
        <Button
          type="submit"
          className="mt-3 w-100"
          variant="danger"
          style={{ backgroundColor: "#800000", border: "none" }}
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );

  return (
    <>
      <ManageAccountOptsPage
        title={"Remove Account"}
        background={background}
        body={
          <PasswordWrapper
            user={props.user}
            requiresOTP
            setPassword={(password) => {
              setFormValue({ ...formValue, password });
            }}
          >
            {deleteBody}
          </PasswordWrapper>
        }
      />
    </>
  );
}
