import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form } from "react-bootstrap";

export default function PasswordInput(props) {
  const [isShowPwd, setIsShowPwd] = useState(false);

  const formType = isShowPwd ? "text" : "password";

  const iconButtonStyle = {
    position: "absolute",
    padding: 5,
    top: 0,
    bottom: 0,
    margin: "0 auto",
    right: 5,
    display: "flex",
    alignItems: "center",
  };

  return (
    <div style={{ position: "relative" }}>
      <Form.Control type={formType} autoComplete="new-password" {...props} />
      <div
        style={iconButtonStyle}
        onClick={() => {
          setIsShowPwd(!isShowPwd);
        }}
      >
        <FontAwesomeIcon icon={isShowPwd ? "eye-slash" : "eye"} />
      </div>
    </div>
  );
}
