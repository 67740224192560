import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "./Home";
import CancelSponsorship from "./CancelSponsorship"
import ChangeQuestionsAndAnswers2 from "./ManageAccountOptions/ChangeQuestionsAndAnswers2";
import ChangePassword2 from "./ManageAccountOptions/ChangePassword2";
import RecoverPassword2 from "./ManageAccountOptions/RecoverPassword2";
import ChangePhoneNumber2 from "./ManageAccountOptions/ChangePhoneNumber2";
import RemoveAccount2 from "./ManageAccountOptions/RemoveAccount2";
import UnlockAccount from "./ManageAccountOptions/UnlockAccount";
import VerifyCoupon from "./ManageAccountOptions/VerifyCoupon";

import New from "../components/New";
import Files from "../components/Files";
import Open from "./Open";
import Receive from "./Receive";
import PageNotFound from "./PageNotFound";
// import useGoogleAuth from "../util/useGoogleAuth";
import SignInModal from "./SignInModal";
import ServiceUsageInfo from "./ServiceUsageInfo";
import ShowServiceClass from "./ShowServiceClass";
import CancelService from "./CancelService";

import Faq2 from "./Faq2";
import Contact2 from "./Contact2";
import SelectSponsor from "./SelectSponsor";
import UpgradeService from "./UpgradeService";
import AcceptSponsorshipReq from "./AcceptSponsorshipReq";
import CreateSubscription from "./CreateSubscription";
import EditServiceClass2 from "./EditServiceClass2";
import EditClass2 from "./EditClass2";
import Tryfreetrial from "./Tryfreetrial";
import BuyChunks from "./BuyChunks";
import CreateClass2 from "./CreateClass2";
// import NavBar from "./NavBar";
import Footer from "./Footer";
import { Col, Container, Navbar, Offcanvas, Row, Card, Button } from "react-bootstrap";

import HowToUse from "./HowToUse";
import { serverUrl } from "../util/SecureCommunication";
import AccessDeniedModal from "./AccessDeniedModal";
import SelectChunks from "./SelectChunks";
import SelectFileChunks from "./SelectFileChunks";
import SideBar from "./SideBar";
import axios from "axios";
import useMicrosoftAuth from "../util/useMicrosoftAuth";
import BannerImage from "./BannerImage";
import CreateAccount, { ACCOUNT_STEPS } from "./CreateAccount";
import { postWithSecureCredentials } from "../functions/securePost";

const SERVICE_STATUS = {
  FT_INELIGIBLE: 0,
  FT_ELIGIBLE: 1,
  FT_ACTIVE: 2,
  PAID_USER: 3,
  PLAN_STATE_SPONSORED: 4,
  BUY_SHARE_CHUNK_ELIGIBLE: 5,
  BUY_FILE_CHUNK_ELIGIBLE: 6,
  BUY_BOTH_CHUNK_ELIGIBLE: 7,
};

export function getPageImage() {
  const IMG_GENERAL = "/img/CYN_Cynorix.jpg";
  const IMG_SIGNUP = "/img/CYN_Technologies.jpg";
  const IMG_SFS = "/img/CYN_sec_sharing_small.jpg";
  const IMG_HELP = "/img/CYN_How_small.jpg";
  //const IMG_SPONSORSHIP = "/img/CYN_Sponsor.jpg";

  switch (window.location.pathname) {
    case "/set-password":
    case "/unlock":
    case "/remove-account":
    case "/change-security-questions":
    case "/change-password":
    case "/recover-password":
    case "/change-phone-number":
      return IMG_SIGNUP;
    case "/new":
    case "/open":
    case "/receive":
      return IMG_SFS;
    case "/faq":
    case "/contact":
      return IMG_HELP;
    // case "/select-sponsor":
    //   return IMG_SPONSORSHIP;
    default:
      return IMG_GENERAL;
  }
}
const App = () => {
  // temporarily restrict api to these.
  const [
    user,
    isAuthorizing,
    handleAuthClick,
    handleRegistration,
    signOut,
    msftReauthPopup,
  ] = useMicrosoftAuth();
/*  const { refreshKey, isKeyGenerating } = useSecureCommunication(
    user,
    window.gapi
  );*/
  const [showDrawer, setShowDrawer] = useState(true);
  const [isSignedUp, setIsSignedUp] = useState(ACCOUNT_STEPS.LOADING);
  const [userServiceStatus, setUserServiceStatus] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthorizing) {
      // if loading
      setIsSignedUp(ACCOUNT_STEPS.LOADING);
    } else if (user === null) {
      // if user doesn't exist
      setIsSignedUp(ACCOUNT_STEPS.OAUTH);
    } else {
      setIsSignedUp(ACCOUNT_STEPS.LOADING);
      axios.post(`${serverUrl}checkPerm`, { userId: user.id }).then((res) => {
        if (res.data.data) {
          axios.post(`${serverUrl}getQuestions`, { userId: user.id })
            .then((res) => {
              if (res.data.data === null) {
                setIsSignedUp(ACCOUNT_STEPS.SET_SECURITY_QUESTIONS);
              } else {
                setIsSignedUp(ACCOUNT_STEPS.ACCOUNT_CREATED);
              }
            });
        } else {
          setIsSignedUp(ACCOUNT_STEPS.SET_PASSWORD);
        }
      });
    }
  }, [isAuthorizing, user, setIsSignedUp]);

  useEffect(() => {
    if (user !== null) {
      checkFreetrialEligible(user);
    }
  }, [user, location]);

  // perform a check if account is locked
  // also more options if the user is paid
  function checkFreetrialEligible(user) {
    // Post to server retrieve questions from database
    postWithSecureCredentials(`${serverUrl}checkFreetrialEligible`, {
        userId: user.id,
        userEmail: user.email,
      })
      .then((res) => {
        const { freetrial, paid, fileSharesLeft, fileEncryptsLeft, sponsored } = res.data.data;
        if (freetrial === "active" && paid === false) {
          setUserServiceStatus(SERVICE_STATUS.FT_ACTIVE);
        } else if (freetrial === "eligible" && paid === false) {
          setUserServiceStatus(SERVICE_STATUS.FT_ELIGIBLE);
        } else if (freetrial === "ineligible") {
          if (paid === true) {
            if (fileSharesLeft <= 0 && fileEncryptsLeft <= 0) {
              setUserServiceStatus(SERVICE_STATUS.BUY_BOTH_CHUNK_ELIGIBLE);
            } else if (fileSharesLeft <= 0 && !(fileEncryptsLeft <= 0)) {
              setUserServiceStatus(SERVICE_STATUS.BUY_SHARE_CHUNK_ELIGIBLE);
            } else if (fileEncryptsLeft <= 0 && !(fileSharesLeft <= 0)) {
              setUserServiceStatus(SERVICE_STATUS.BUY_FILE_CHUNK_ELIGIBLE);
            } else {
              setUserServiceStatus(SERVICE_STATUS.PAID_USER);
            }
          } else if (sponsored) {
            setUserServiceStatus(SERVICE_STATUS.PLAN_STATE_SPONSORED)
          }  else if (paid === false) {
            setUserServiceStatus(SERVICE_STATUS.FT_INELIGIBLE);
          } else {
            throw Error('"paid" not a boolean value');
          }
        } else {
          throw Error(
            `Unexpected logic here: freetrial is ${freetrial} and paid is ${paid}`
          );
        }
      })
      .catch((err) => {});
  }

  const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
    if (isAllowed) {
      return <Outlet />;
    }

    return children;
  };

  const RequireSignInRoute = () => {
    if (
      user !== null &&
      isSignedUp !== ACCOUNT_STEPS.LOADING &&
      isSignedUp !== ACCOUNT_STEPS.ACCOUNT_CREATED
    ) {
      navigate("/set-password");
    }
    return (
      <ProtectedRoute
        isAllowed={user !== null /*&& isSignedUp !== ACCOUNT_STEPS.LOADING*/}
      >
        <SignInModal
          handleAuth={handleAuthClick}
          handleRegistration={handleRegistration}
          isAuthorizing={isAuthorizing}
        />
      </ProtectedRoute>
    );
  };

  const maintenanceEmail = process.env.REACT_APP_MAINTENANCE_EMAIL;
  const isUsageInfoPage = location.pathname === "/service-usage-info";
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <div
      style={{
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {/* Background Image */}
      <div
        style={{
          // TODO: create a url map to the different page urls
          backgroundImage: `url(${getPageImage()})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "fixed",
          height: "100vh",
          width: "100vw",
          filter: "blur(10px) opacity(15%)",
          display: isHomePage ? "none" : null,
        }}
      />
      {/* Navbar */}
      <Navbar bg="white" className="px-3" expand="false">
        <Navbar.Brand className="p-2">
          <img
            src="/img/CYN_logo.png"
            width={203}
            alt="cynorix logo"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/home");
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className={isUsageInfoPage ? null : "d-lg-none"}
          placement="end"
          onClick={() => {
            setShowDrawer(true);
          }}
          style={{
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
          }}
        />
      </Navbar>
      {/* App contents */}
      <Container
        fluid
        className="py-3 px-xs-0 px-md-5"
        style={{
          display:
            location.pathname === "/" || location.pathname === "/home"
              ? "none"
              : null,
        }}
      >
        <Row className="px-xs-0 px-md-5">
          {/* Page Contents */}
          <Col className="px-2" style={{ width: "100%" }}>
            <Routes>
              <Route
                element={
                  <ProtectedRoute
                    isAllowed={user === null || user.email !== maintenanceEmail}
                  >
                    <Navigate to={"/maintenance-dashboard"} />
                  </ProtectedRoute>
                }
              >
                <Route exact path="/" element={<Home />} />
                <Route
                  exact
                  path="/set-password"
                  element={
                    <CreateAccount
                      user={user}
                      isSignedUp={isSignedUp}
                      setIsSignedUp={setIsSignedUp}
                      signIn={handleAuthClick}
                    />
                  }
                />
                <Route element={<RequireSignInRoute />}>
                  <Route exact path="/new" element={<New user={user} />} />
                  <Route exact path="/sheets" element={<Files user={user} />} />
                  {/* <Route exact path="/maintenance" element={<Maintenance />} /> */}
                  <Route exact path="/open" element={<Open user={user} />} />
                  <Route
                    exact
                    path="/receive"
                    element={<Receive user={user} />}
                  />

                  <Route
                    exact
                    path="/service-usage-info"
                    element={<ServiceUsageInfo user={user} />}
                  />
                  <Route
                    exact
                    path="/unlock"
                    element={<UnlockAccount user={user} />}
                  />
                  {/* <Route
                    exact
                    path="/service"
                    element={<Service user={user} />}
                  /> */}
                  <Route
                    exact
                    path="/show-service-class"
                    element={<ShowServiceClass user={user} />}
                  />
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          true
                        }
                      >
                        <Card
                          body
                          style={{
                            border: "none",
                            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <BannerImage />
                          <h4 className="mt-3">
                            <b>Free Trial</b>
                          </h4>
                          <hr className="mt-0" />
                          {userServiceStatus === SERVICE_STATUS.FT_ACTIVE
                            ? "Your trial is already active."
                            : "You are not eligible to access this page."}
                          <Button
                            className="w-100 mt-3"
                            variant="secondary"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Go Back
                          </Button>
                        </Card>
                      </ProtectedRoute>
                    }
                  >
                    <Route
                      exact
                      path="/free-trial"
                      element={<Tryfreetrial user={user} />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/create-subscription"
                    element={<CreateSubscription user={user} />}
                  />

                  <Route
                    exact
                    path="/cancel-service"
                    element={<CancelService user={user} />}
                  />
                  <Route path="/verify-coupon" element={<VerifyCoupon user={user}/> } />

                  <Route
                    exact
                    path="/select-sponsor"
                    element={<SelectSponsor user={user} />}
                  />
                  <Route
                    exact
                    path="/remove-account"
                    element={
                      <RemoveAccount2
                        user={user}
                        signOut={signOut}
                        msftReauthPopup={msftReauthPopup}
                      />
                    }
                  />
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          userServiceStatus >= SERVICE_STATUS.BUY_SHARE_CHUNK_ELIGIBLE
                        }
                      >
                        <Card
                          body
                          style={{
                            border: "none",
                            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <BannerImage />
                          <h4 className="mt-3">
                            <b>Buy Chunks</b>
                          </h4>
                          <hr className="mt-0" />
                          You are not eligible to access this page.
                          <Button
                            className="w-100 mt-3"
                            variant="secondary"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Go Back
                          </Button>
                        </Card>
                      </ProtectedRoute>
                    }
                  >
                    <Route
                      exact
                      path="/buy-chunks"
                      element={<BuyChunks user={user} />}
                    />
                    <Route
                      exact
                      path="/select-chunks"
                      element={<SelectChunks user={user} />}
                    />
                    <Route
                      exact
                      path="/select-file-chunks"
                      element={<SelectFileChunks user={user} />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/upgrade-service"
                    element={<UpgradeService user={user} />}
                  />
                  <Route
                    exact 
                    path="/cancel-sponsorship"
                    element={<CancelSponsorship user={user} />}
                  />
                  <Route
                    exact 
                    path="/accept-sponsorship-req"
                    element={<AcceptSponsorshipReq user={user} />}
                  />
                  <Route
                    exact
                    path="/change-security-questions"
                    element={<ChangeQuestionsAndAnswers2 user={user} />}
                  />
                </Route>

                <Route exact path="/HowToUse" element={<HowToUse />} />
                {/* <Route
                  exact
                  path="/select-sponsor"
                  element={<SelectSponsor />}
                /> */}
              </Route>
              <Route element={<RequireSignInRoute />}>
                <Route
                  exact
                  path="/change-password"
                  element={<ChangePassword2 user={user} />}
                />
                <Route
                  exact
                  path="/recover-password"
                  element={<RecoverPassword2 user={user} />}
                />
                <Route
                  exact
                  path="/change-phone-number"
                  element={<ChangePhoneNumber2 user={user} />}
                />
                <Route
                  element={
                    <ProtectedRoute
                      isAllowed={
                        user !== null && user.email === maintenanceEmail
                      }
                    >
                      <AccessDeniedModal />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    exact
                    path="/maintenance-dashboard"
                    element={<EditServiceClass2 />}
                  />
                  <Route
                    exact
                    path="/edit-class/:classId"
                    element={<EditClass2 />}
                  />
                  <Route
                    exact
                    path="/create-class"
                    element={<CreateClass2 user={user} />}
                  />
                </Route>
              </Route>
              <Route exact path="/faq" element={<Faq2 />} />
              <Route exact path="/contact" element={<Contact2 />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Col>
          {/* Sidebar */}
          {["/home", "/"].some((item) => location.pathname === item) ? null : (
            <Col
              className="px-2 d-none d-lg-inline"
              xs={12}
              style={{ flexBasis: 350, flexShrink: 1 }}
            >
              <Offcanvas
                show={showDrawer}
                onHide={() => {
                  setShowDrawer(false);
                }}
                responsive={isUsageInfoPage ? null : "lg"}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Navigation</Offcanvas.Title>
                </Offcanvas.Header>
                <SideBar
                  user={user}
                  signOut={signOut}
                  handleAuthClick={handleAuthClick}
                  isAuthorizing={isAuthorizing}
                  maintenanceEmail={maintenanceEmail}
                  setIsSignedUp={setIsSignedUp}
                  userServiceStatus={userServiceStatus}
                />
              </Offcanvas>
            </Col>
          )}
        </Row>
      </Container>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
      </Routes>
      <div style={{ height: 50 }} />
      <Footer />
    </div>
  );
};

export default App;
