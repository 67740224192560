import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div className="Footer text-bold">
        <p>
          <br></br>
          Check out{" "}
          <a href="https://www.cynorix.com/" target="_blank" rel="noreferrer">
            Cynorix.com
          </a>
          <br></br>
          &copy; Cynorix 2023, Cynorix Inc. All Rights Reserved
        </p>
      </div>
    </>
  );
}

