import React, { useState, useEffect } from "react";
import { Form, Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./App.css";
import axios from "axios";
import BannerImage from "./BannerImage";
import FeedbackAlert from "./FeedbackAlert";
import LoadingButton from "./LoadingButton";
import { postWithSecureCredentials } from "../functions/securePost";

const firebaseAddress = process.env.REACT_APP_SERVER_URL;

export default function SelectChunks(props) {
  const [numOfChunks, setNumOfChunks] = useState(0);
  const [extraRate, setExtraRate] = useState(0);
  const [cost, setCost] = useState(0);
  const [subName, setSubName] = useState("");
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    postWithSecureCredentials(`${firebaseAddress}checkFreetrialEligible`, {
        userId: props.user.id,
        userEmail: props.user.email,
      })
      .then((res) => {
        const { fileSharesLeft } = res.data.data;
        if (fileSharesLeft > 0) {
          navigate("/");
        }
      })
      .catch((err) => {
        alert("error");
      });
    setCost((Math.round(numOfChunks * extraRate * 100) / 100).toFixed(2));
  }, [numOfChunks, extraRate, navigate, props.user.email, props.user.id]);

  useEffect(() => {
    const getSubscriptionInfo = () => {
      postWithSecureCredentials(`${firebaseAddress}getCurrentProductInfo`, {
            userId: props.user.id,
          })
          .then((res) => {
            setSubName(res.data.data.Name);
            setExtraRate(
                (Math.round(res.data.data.ExtraRate * 100) / 100).toFixed(2)
            );
            setLoading(false);
          })
          .catch((err) => {});
    };

    if (props.user) {
      getSubscriptionInfo();
    }
  }, [props]);

  //Handle Create Class
  const handleCreateClass = async (e) => {
    e.preventDefault();
    if (cost < 0.5) {
      setFeedback({
        message: "Total Cost must be greater than $0.50",
        variant: "danger",
      });
      return;
    }
    setFeedback({
      message: "Proceeding to checkout...",
      variant: "success",
      loading: true,
    });
    setTimeout(() => {
      setFeedback(null);
      navigate("/buy-chunks", { state: { quantity: numOfChunks, type: "share" } });
    }, 1500);
  };

  return (
    <Card
      body
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BannerImage />
      <h4 className="mt-3">
        <b>Select Chunks</b>
      </h4>
      <hr className="mt-0" />
      {!loading && (
        <Form onSubmit={handleCreateClass}>
          <Form.Group className="row mb-3">
            <Form.Label column>Subscription Name</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control disabled defaultValue={subName} />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label column>Quantity of Chunks</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control
                min={0}
                type="number"
                required
                value={numOfChunks}
                onChange={(event) => setNumOfChunks(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label column>Extra Rate Per Chunk</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control disabled defaultValue={`$${extraRate}`} />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3">
            <Form.Label column>Total Cost</Form.Label>
            <Col xs={12} lg={8} xl={9}>
              <Form.Control disabled value={`$${cost}`} />
            </Col>
          </Form.Group>
          <LoadingButton
            loading={feedback && feedback.loading === true}
            className="w-100"
            type="submit"
          >
            Proceed
          </LoadingButton>
          <FeedbackAlert feedback={feedback} className="mt-3 mb-0" />
        </Form>
      )}
    </Card>
  );
}
