import axios from "axios";
import { useState, useEffect } from "react";
import {
  createUserKey,
  encodeReq,
  formCheck,
  uint8ArrayToWordArray,
  XOR,
  arraysEqual,
  convertWordArrayToUint8Array,
} from "../functions/encoding";
import CryptoJS from "crypto-js";
import { postWithSecureCredentials } from "../functions/securePost";


export const serverUrl = process.env.REACT_APP_SERVER_URL;

const keyLabel = "cynorix_key";
const expireLabel = "cynorix_expire";
const registeredLabel = "registered";


export function useSecureCommunication(user, gapi) {
  const [isKeyGenerating, setIsKeyGenerating] = useState(false);

  function refreshKey() {
    // localStorage.removeItem(keyLabel);
    // clearTimeout(sessionTimeout);
    genKey();
  }

  // Generate the key for the session
  async function genKey() {
    setIsKeyGenerating(true);

    // for now, send request to backend with some basic info
    axios
      .post(`${serverUrl}generateKey`, {
        userExists: true,
        userEmail: user.email,
        pbValidVecBase64: null,
      })
      .then((res) => {
        setIsKeyGenerating(false);
      })
      .catch((err) => {
        setIsKeyGenerating(false);
      });
  }

  //on error, clear key-related cookies and try again
  function invalidKey() {
    // automatically refresh in 3 seconds
    setTimeout(() => {
      // localStorage.removeItem(registeredLabel);
      // localStorage.removeItem(expireLabel);
      // localStorage.removeItem(keyLabel);
    }, 3000);
  }

  useEffect(() => {
    beginSession();

    return () => {
      endSession();
    };
  }, []);

  //calculates time from key expiry, and sets timeout to block page when key expires
  function beginSession() {
    var expire = localStorage.getItem(expireLabel);

    var currDate = new Date();
    var expireDate = new Date(parseInt(expire));
    var genDate = new Date(parseInt(expire));

    genDate.setMinutes(expireDate.getMinutes() - 30);
    expireDate.setMinutes(expireDate.getMinutes() - 2); //add 2 minute buffer

    var diffInMs = expireDate.getTime() - currDate.getTime();
    setTimeout(endSession, diffInMs);

    // refreshKey();
  }

  //triggers modal to indicate session has ended
  function endSession() {
    localStorage.removeItem(expireLabel);
    localStorage.removeItem(keyLabel);
    localStorage.removeItem(registeredLabel);
  }

  return {
    refreshKey,
    isKeyGenerating,
  };
}


window.addEventListener("beforeunload", () => {
  if (localStorage.getItem(expireLabel) === "generating") {
    localStorage.removeItem(expireLabel);
    localStorage.removeItem(keyLabel);
    localStorage.removeItem(registeredLabel);
  }
});

// --- general use functions --- //

export async function verifyPassword(
  userId,
  password,
  redirectIfLocked = () => {}
) {
  const check = formCheck(password + userId);

  return new Promise((resolve, reject) => {
    postWithSecureCredentials(`${serverUrl}verifyPassword`, {
        userId: userId,
        toCheck: check,
      })
      .then((res) => {
        if (typeof res.data.data.valid === "boolean") {
          // temp fix
          if (res.data.data.locked === true) {
            reject("Account is locked.");
            redirectIfLocked();
          }
          resolve(res.data.data.valid);
        } else {
          reject("Valid is not a boolean type: " + res.data.data.valid);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendEmail(subject, emailMsg, recipient, user) {
  //compose email and encode to base64 string
  const messageParts = [
    "From: " + user.email,
    "To: " + recipient,
    "Content-Type: text/html; charset=utf-8",
    "MIME-Version: 1.0",
    `Subject: ${subject}`,
    "",
    emailMsg,
  ];
  const message = messageParts.join("\n");
  var encodedMessage = btoa(message);

  return new Promise((resolve, reject) => {
    window.gapi.client.gmail.users.messages
      .send({
        userId: "me",
        resource: {
          raw: encodedMessage,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject("sending email failed: " + err);
      });
  });
}

// uses gmail api to send email from current user's account
export function sendEmailMicrosoft(
  subject,
  emailMsg,
  recipient,
  user,
  accessToken
) {
  //compose email and encode to base64 string
  let data = {
    message: {
      subject: subject,
      body: {
        contentType: "HTML",
        content: emailMsg,
      },
      toRecipients: [
        {
          emailAddress: {
            address: recipient,
          },
        },
      ],
    },
  };
  return axios.post("https://graph.microsoft.com/v1.0/me/sendMail", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-type": "application/json",
    },
  });
}

export async function downloadFile(
  pwd,
  fileId,
  user,
  pushFeedback = () => {},
  redirectIfLocked = () => {}
) {
  let cid = fileId.split("!")[0];
  // validate password
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Validating Password...`,
  });
  try {
    const isPwdValid = await verifyPassword(user.id, pwd, redirectIfLocked);

    if (!isPwdValid) {
      pushFeedback({
        variant: "warning",
        message: `Password incorrect for user ${user.name}(${user.email}).`,
      });
      return;
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occurred while verifying password.`,
    });
  }

  // get file contents
  try {
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Retrieving File Contents...`,
    });
    const res = await window.gapi.client.drive.files.get({
      fileId: fileId,
      alt: "media",
    });

    await getTransformed(cid,pwd, res.body, fileId, user, pushFeedback);
  } catch (err) {
    const reason = err.result.error.errors[0].reason;
    if (reason === "notFound") {
      pushFeedback({
        variant: "danger",
        message: `File not found.`,
      });
    } else {
      pushFeedback({
        variant: "danger",
        message: `An unexpected error occurred while getting the file.`,
      });
    }
  }
}

export async function downloadFileMicroSoft(
  pwd,
  fileId,
  user,
  accessToken,
  owner,
  pushFeedback = () => {},
  pushDownloadProgress = () => {},
  redirectIfLocked = () => {}
) {
  // validate password
  let cid = fileId.split("!")[0];
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Validating Password...`,
  });
  try {
    const isPwdValid = await verifyPassword(user.id, pwd, redirectIfLocked);

    if (!isPwdValid) {
      pushFeedback({
        variant: "warning",
        message: `Password incorrect for user ${user.name}(${user.email}).`,
      });
      return;
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occurred while verifying password.`,
    });
  }

  // get file contents
  try {
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Retrieving File Contents...`,
    });
    var download_url;
    if (owner) {
      download_url =
        "https://graph.microsoft.com/v1.0/me/drive/items/" + fileId;
      axios
        .get(download_url, {
          headers: {
            Authorization: "Bearer " + accessToken,
            'Content-Type': 'application/json'
          },
        })
        .then(async (res) => {
          await getTransformed(
                cid,
                pwd,
                fileId,
                user,
                res.data.name,
                res.data.size,
                accessToken,
                pushFeedback,
                pushDownloadProgress
              );
        });
    } else {
      console.log("Not owner")
      // If not owner are checking 
      axios
        .get("https://graph.microsoft.com/v1.0/me/drive/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => {
          console.log("Checking for the right drive",res)
          console.log("cid", cid)
          console.log("fileId",fileId)
          axios
            .get(
              "https://graph.microsoft.com/v1.0/drives/" +
                cid +
                "/items/" +
                fileId,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            )
            .then(async (res) => {
              console.log("res inside", res)
              await getTransformed(
                    cid,
                    pwd,
                    fileId,
                    user,
                    res.data.name,
                    res.data.size,
                    accessToken,
                    pushFeedback,
                    pushDownloadProgress
                  );
            });
        });
    }
  } catch (err) {
    const reason = err.result.error.errors[0].reason;
    if (reason === "notFound") {
      pushFeedback({
        variant: "danger",
        message: `File not found.`,
      });
    } else {
      pushFeedback({
        variant: "danger",
        message: `An unexpected error occurred while getting the file.`,
      });
    }
  }
}

/*function str2ab(str) {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}*/

function bytesToWordArray(bytes) {
  var words = [];
  for (var i = 0; i < bytes.length; ++i) {
    var j = 24 - (i % 4) * 8;
    words[i >>> 2] |= bytes[i] << j;
  }
  return CryptoJS.lib.WordArray.create(words, bytes.length);
}

function wordArrayToBytes(wa) {
  let bytes = [];
  for (var i = 0; i < wa.sigBytes; ++i) {
    var j = 24 - (i % 4) * 8;
    bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
  }
  return new Uint8Array(bytes);
}

async function getTransformed(
  cid,
  pwd,
  fileId,
  user,
  fileName,
  fileSize,
  accessToken,
  pushFeedback = () => {},
  pushDownloadProgress = () => {}
) {
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Decrypting file...`,
  });
  pushDownloadProgress({
    loading: true,
    now: 0
  });
  // reform user encryption key
  var userKey = createUserKey(fileId, pwd, user.id);
  var pwdCheck = formCheck(pwd + user.id);
  try {
    const res = await postWithSecureCredentials(`${serverUrl}getTransformedKey`, {
      fileId: fileId,
      toCheck: pwdCheck,
      userId: user.id,
    });

    // MAKE SURE THAT THE FILE EXISTS AND CLAIMED IS TRUE
    const { exists, claimed } = res.data.data;
    if (exists === false) {
      pushFeedback({
        variant: "danger",
        message: `The file you are looking for cannot be found.`,
      });
      return;
    } else if (claimed === false) {
      pushFeedback({
        variant: "danger",
        message: `File Unclaimed.`,
      });
      return;
    }
    pushDownloadProgress({
      loading: true,
      now: 0
    });

    // GET KEY FOR DECRYPTING FILE
    const encFileKey = res.data.data.key;
    const fileKeyAsBytes = XOR(encFileKey, userKey);
    const fileKeyAsWords = bytesToWordArray(fileKeyAsBytes);

    const SLICE_SIZE = 50 * 1024 * 1024; //50 MB

    let firstSlice = await axios.get(`https://graph.microsoft.com/v1.0/drives/${cid}/items/${fileId}/content`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        range: `bytes=${0}-${SLICE_SIZE-1}`
      },
      responseType: 'blob'
    });
    
    const iv = bytesToWordArray(
      new Uint8Array(await firstSlice.data.slice(0,16).arrayBuffer())
    );

    let aesDec = CryptoJS.algo.AES.createDecryptor(fileKeyAsWords, {
      mode: CryptoJS.mode.CFB,
      iv: iv
    });

    let decryptedBlobParts =[];

    let firstInput = CryptoJS.lib.WordArray.create( await firstSlice.data.slice(16).arrayBuffer() );
    let firstDecryptedSlice = aesDec.process(firstInput);
    decryptedBlobParts.push(new Blob([wordArrayToBytes(firstDecryptedSlice)]));
    firstSlice = null; //let javascript garbage collect

    pushDownloadProgress({
      loading: true,
      now: Math.round(SLICE_SIZE*100/fileSize)
    });

    let start = SLICE_SIZE;

    const delay = () => new Promise((resolve) => setTimeout(resolve,0)); //bypass react optimization
    while (start < fileSize) {
      let slice = await axios.get(`https://graph.microsoft.com/v1.0/drives/${cid}/items/${fileId}/content`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        range: `bytes=${start}-${start+SLICE_SIZE-1}`
      },
      responseType: 'blob' 
    });

      let wordArrayInput = CryptoJS.lib.WordArray.create( await slice.data.arrayBuffer() );
      let decryptedSlice = aesDec.process(wordArrayInput);

      decryptedBlobParts.push(new Blob([wordArrayToBytes(decryptedSlice)]));

      start += SLICE_SIZE;

      pushDownloadProgress({
        loading: true,
        now: Math.round(start*100/fileSize)
      });
      await delay();
    }

    decryptedBlobParts.push(new Blob([wordArrayToBytes(aesDec.finalize())]));
    const decryptedFileBlob = new Blob(decryptedBlobParts, { type: "application/octet-stream" });

    // separate file signature from file
    const decLength = decryptedFileBlob.size;
    console.log(decLength);
    const sigLength = 32;
    const sigAsBlob = decryptedFileBlob.slice(decLength - sigLength);
    const sigAsBytes = new Uint8Array(await sigAsBlob.arrayBuffer());
    const fileAsBlob = decryptedFileBlob.slice(0, decLength - sigLength);

    // get hashed signature of decrypted file to compare with expected signature
    const sigCheckWords = CryptoJS.SHA256(fileAsBlob); // create file signature
    const sigCheckAsBytes = wordArrayToBytes(sigCheckWords);

    pushDownloadProgress({
      loading: false,
      now: 0
    });

    // download file if signature is correct
    if (!arraysEqual(sigAsBytes, sigCheckAsBytes)) {
      pushFeedback({
        variant: "danger",
        message: `The file is corrupted or was incorrectly decrypted. Download aborted.`,
      });
      return;
    }

    try {
      var a = document.createElement("a");
      var url = window.URL.createObjectURL(fileAsBlob);
      a.href = url;
      a.download = fileName.replace(".enc", "");
      a.click();
      window.URL.revokeObjectURL(url);
      pushFeedback(null);
    } catch (err) {
      pushFeedback({
        variant: "danger",
        message: `Error occured while getting title of file. Download aborted.`,
      });
      pushDownloadProgress({
        loading: false,
        now: 0
      });
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occured while trying to retrieve the 
        file's transformed key. Please try again.`,
    });
    pushDownloadProgress({
      loading: false,
      now: 0
    });
  }
}
