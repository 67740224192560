import { useRef, useState, useEffect, useCallback } from "react";

// OTP time allowed to verify
export const SECONDS_ALLOWED_OTP = 185;

// OTP status
export const OTP_STATUS = {
  NOT_SENT: 0,
  SENDING: 1,
  SENT: 2,
};

export default function useOTPTimer(pushFeedback) {
  const [otpTimeLeft, setOtpTimeLeft] = useState(SECONDS_ALLOWED_OTP);
  const [otpStatus, setOtpStatus] = useState(OTP_STATUS.NOT_SENT);

  const activeTimer = useRef();
  const clearTimer = useCallback(() => {
    if (activeTimer.current) {
      clearInterval(activeTimer.current);
    }
  }, []);

  const startTimer = useCallback(() => {
    clearTimer();
    setOtpTimeLeft(SECONDS_ALLOWED_OTP);

    activeTimer.current = setInterval(() => {
      setOtpTimeLeft((t) => t - 1);
    }, 1000);
  }, [clearTimer]);

  useEffect(() => {
    return clearTimer;
  }, [clearTimer]);

  useEffect(() => {
    if (otpTimeLeft === 0) {
      setOtpStatus(OTP_STATUS.NOT_SENT);
      pushFeedback({
        variant: "warning",
        message: "OTP Expired, please resend code to continue",
      });
    }
  }, [otpTimeLeft, pushFeedback]);

  useEffect(() => {
    if (otpStatus === OTP_STATUS.SENT) {
      startTimer();
    } else {
      clearTimer();
    }
  }, [otpStatus, clearTimer, startTimer]);

  const getFeedback = () => {
    if (otpStatus === OTP_STATUS.SENT) {
      return {
        message: `A one time password has been sent to your phone. Time
        remaining: ${Math.floor(otpTimeLeft / 60)}:${
          (otpTimeLeft % 60 < 10 ? "0" : "") + (otpTimeLeft % 60)
        }`,
        variant: "info",
      };
    } else {
      return null;
    }
  };

  return {
    otpStatus,
    setOtpStatus,
    timerFeedback: getFeedback(),
    startTimer,
    clearTimer,
  };
}
