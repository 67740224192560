import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutPage from "./CheckoutPage";
import axios from "axios";
import { Card } from "react-bootstrap";
import BannerImage from "./BannerImage";
import FeedbackAlert from "./FeedbackAlert";
import { postWithSecureCredentials } from "../functions/securePost";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const APIkey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(APIkey);

export default function BuyChunks(props) {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [purchased, setPurchased] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const location = useLocation();


  const firebaseAddress = SERVER_URL;

  useEffect(() => {
    const checkout = {
      user_id: "",
    };

    if (location.state === null || !location.state.quantity) {
      setFeedback({
        variant: "warning",
        message: "You have nothing to checkout. Redirecting...",
        loading: true,
      });
      setTimeout(() => {
        navigate("/select-chunks");
      }, 2000);
      return;
    }

    const quantity = location.state.quantity;
    checkout.quantity = parseInt(quantity);
    const type = location.state.type;

    const subscribe = async () => {
      if (props.user && !purchased) {
        checkout.user_id = props.user.id;
        postWithSecureCredentials(`${firebaseAddress}chunkCheckout`, {
            checkout,
            type,
          })
          .then((res) => {
            setClientSecret(res.data.data.clientSecret);
            setPurchased(true);
          })
          .catch((err) => {});
      }
    };
    subscribe();
  }, [purchased, props, firebaseAddress, location.state, navigate]);

  const options = {
    clientSecret,
    appearance: {
      // Check Stripe Appearance for more customizations
      theme: "stripe",
      // labels: "floating",
      rules: {
        ".Tab": {
          border: "1px solid #E0E6EB",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(255, 255, 255, 0.02)",
        },

        ".Tab:hover": {
          color: "var(--colorText)",
        },

        ".Tab--selected": {
          borderColor: "#E0E6EB",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
        },

        ".Input--invalid": {
          boxShadow:
            "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
        },
      },
    },
  };

  return (
    <Card
      body
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BannerImage />
      <h4 className="mt-3">
        <b>Buy Chunks</b>
      </h4>
      <hr className="mt-0" />
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutPage clientSecret={clientSecret} />
        </Elements>
      )}
      <FeedbackAlert feedback={feedback} className="mb-0" />
    </Card>
  );
}
