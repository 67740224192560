import CryptoJS from "crypto-js";
const keyLabel = "cynorix_key"; // from secure-comm.js
const AESLOOPS = 16384;

/**
 * Converts a WordArray to a Uint8Array (typed array)
 * @param {WordArray} wordArray
 */
export function convertWordArrayToUint8Array(wordArray) {
  var arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
  var length = wordArray.hasOwnProperty("sigBytes")
    ? wordArray.sigBytes
    : arrayOfWords.length * 4;
  var uInt8Array = new Uint8Array(length),
    index = 0,
    word,
    i;

  for (i = 0; i < length; i++) {
    word = arrayOfWords[i];
    uInt8Array[index++] = word >> 24;
    uInt8Array[index++] = (word >> 16) & 0xff;
    uInt8Array[index++] = (word >> 8) & 0xff;
    uInt8Array[index++] = word & 0xff;
  }
  return uInt8Array;
}

const base64abc = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "+",
  "/",
];

const base64codes = [
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
  255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255,
  255, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255,
  255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 255, 255, 255, 255, 255, 255, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
];

export function getBase64Code(charCode) {
  if (charCode >= base64codes.length) {
    throw new Error("Unable to parse base64 string.");
  }
  const code = base64codes[charCode];
  if (code === 255) {
    throw new Error("Unable to parse base64 string.");
  }
  return code;
}

export function base64ToBytes(str) {
  if (str.length % 4 !== 0) {
    throw new Error("Unable to parse base64 string.");
  }
  const index = str.indexOf("=");
  if (index !== -1 && index < str.length - 2) {
    throw new Error("Unable to parse base64 string.");
  }
  let missingOctets = str.endsWith("==") ? 2 : str.endsWith("=") ? 1 : 0,
    n = str.length,
    result = new Uint8Array(3 * (n / 4)),
    buffer;
  for (let i = 0, j = 0; i < n; i += 4, j += 3) {
    buffer =
      (getBase64Code(str.charCodeAt(i)) << 18) |
      (getBase64Code(str.charCodeAt(i + 1)) << 12) |
      (getBase64Code(str.charCodeAt(i + 2)) << 6) |
      getBase64Code(str.charCodeAt(i + 3));
    result[j] = buffer >> 16;
    result[j + 1] = (buffer >> 8) & 0xff;
    result[j + 2] = buffer & 0xff;
  }
  return result.subarray(0, result.length - missingOctets);
}

export function bytesToBase64(bytes) {
  let result = "",
    i,
    l = bytes.length;
  for (i = 2; i < l; i += 3) {
    result += base64abc[bytes[i - 2] >> 2];
    result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
    result += base64abc[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)];
    result += base64abc[bytes[i] & 0x3f];
  }
  if (i === l + 1) {
    // 1 octet yet to write
    result += base64abc[bytes[i - 2] >> 2];
    result += base64abc[(bytes[i - 2] & 0x03) << 4];
    result += "==";
  }
  if (i === l) {
    // 2 octets yet to write
    result += base64abc[bytes[i - 2] >> 2];
    result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
    result += base64abc[(bytes[i - 1] & 0x0f) << 2];
    result += "=";
  }
  return result;
}

export function uint8ArrayToString(uint8array) {
  var str = "";
  for (let i = 0; i < uint8array.length; i++) {
    if (i !== 0) str += "-";
    str += uint8array[i].toString();
  }
  return str;
}

export function stringToUintArray(str) {
  var strArray = str.split("-");
  var uint8 = new Uint8Array(strArray.length);

  for (let i = 0; i < uint8.length; i++) {
    uint8[i] += parseInt(strArray[i]);
  }

  return uint8;
}

/**
 * Converts Uint8Array to WordArray
 * @param {Uint8Array} u8Array
 */
export function uint8ArrayToWordArray(u8Array) {
  var words = [],
    i = 0,
    len = u8Array.length;

  while (i < len) {
    words.push(
      (u8Array[i++] << 24) |
        (u8Array[i++] << 16) |
        (u8Array[i++] << 8) |
        u8Array[i++]
    );
  }

  return {
    sigBytes: words.length * 4,
    words: words,
  };
}

/**
 * Xors two Uint8Arrays together
 * @param {Uint8Array} a
 * @param {Uint8Array} b
 */
export function XOR(a, b) {
  var result = new Uint8Array(a.length);
  for (let i = 0; i < a.length; i++) {
    result[i] = a[i] ^ b[i];
  }
  return result;
}

/**
 * Prepares an encrypted request to send to the server
 * @param {JSON} jsonReq
 * @param {String} userId
 */
export function encodeReq(request, userEmail) {
  // var key = localStorage.getItem(keyLabel);
  // var jsonStr = JSON.stringify(jsonReq);
  // var encMsg = CryptoJS.AES.encrypt(jsonStr, key).toString();

  // var keyCheck = formCheck(key).toString();
  return {
    userEmail: userEmail,
    // encrypted: null,
    // keyCheck: null,
    ...request,
  };
}

/**
 * Decodes the encrypted response from the server
 * @param {JSON} jsonRes
 */
export function decodeRes(jsonRes) {
  var key = localStorage.getItem(keyLabel);
  var encrypted = jsonRes.encrypted;
  var decMsg = CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);
  var jsonToReceive = JSON.parse(decMsg);
  return jsonToReceive;
}

/**
 * creates check 3 word check from 32 word string
 * @param {string} unhashed
 */
export function formCheck(unhashed) {
  var hashedArray = hashString(unhashed);

  var length = 3;
  var check = new Uint8Array(length),
    i;
  for (i = 0; i < length; i++) {
    check[i] = hashedArray[i];
  }

  return check;
}

/**
 * hashes string 10000 times
 * @param {string} unhashed
 */
export function hashString(unhashed, reps = 1000) {
  var i = 0,
    hashed = unhashed;
  for (i = 0; i < reps; i++) {
    hashed = CryptoJS.SHA256(hashed);
  }

  var hashedArray = convertWordArrayToUint8Array(hashed);
  return hashedArray;
}

/**
 * creates user file key as Uint8Array
 * @param {string} fileId
 * @param {string} password
 * @param {string} userId
 */
export function createUserKey(fileId, password, userId) {
  var hashedIds = CryptoJS.SHA256(userId + fileId);
  var hashedIdsArr = convertWordArrayToUint8Array(hashedIds);

  var hashedPass = hashString(password);

  var key = XOR(hashedIdsArr, hashedPass);

  return key;
}

/**
 * use the five security question answers to generate a verification
 * @param {string} answers

 */
export function generateVerification(answers) {
  return CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(answers)).substring(0, 8);
}

export function arraysEqual(arr1, arr2) {
  var length = arr1.length,
    i = 0;
  for (i = 0; i < length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

const uint8ArrayToUint8 = (array) => {
  var output = "";
  for (const element of array) {
    output += String.fromCharCode(element);
  }
  return output;
};

const uint8ToUint8Array = (str) => {
  let output = [];
  for (const letter of str) {
    output.push(letter.charCodeAt(0));
  }
  return output;
};

const strToBin = (str) => {
  var output = "";
  for (const element of str) {
    output += element.charCodeAt(0).toString(2).padStart(8, "0");
  }
  return output;
};

const reverseBits = (str) => {
  let bits = strToBin(str).split("").reverse().join("");
  let output = "";
  for (let i = 0; i < bits.length / 8; i++) {
    output += String.fromCharCode(
      parseInt(bits.substring(i * 8, (i + 1) * 8), 2)
    );
  }
  return output;
};

const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

const generateKey = (answers) => {
  // Split into vectors of 512 bits (64 characters of 8 bits each)
  let AnswersVectors = answers.match(/.{1,64}/g);

  // Pad last vector with zeros
  AnswersVectors[AnswersVectors.length - 1] = AnswersVectors[
    AnswersVectors.length - 1
  ].padStart(64, "\u0000");

  // Convert strings to Uint8 Arrays to xor
  let Uint8ArrayAnswers = [];
  for (const element of AnswersVectors) {
    Uint8ArrayAnswers.push(uint8ToUint8Array(element));
  }

  // Xor all the vectors
  let xorResult = Uint8ArrayAnswers[0];
  for (var i = 1; i < Uint8ArrayAnswers.length; i++) {
    xorResult = XOR(xorResult, Uint8ArrayAnswers[i]);
  }

  // Convert to Uint8 String to convert to word array
  let xorResultUint8 = uint8ArrayToUint8(xorResult);

  let vector = xorResultUint8;
  let iv = CryptoJS.enc.Base64.parse("9De0DgMTCDFGNokdEEial");

  for (i = 0; i < AESLOOPS; i++) {
    // Create word array for the message and key
    let wordArray = CryptoJS.enc.Latin1.parse(reverseBits(vector));
    let key = CryptoJS.enc.Latin1.parse(vector);

    // Encrypt using AES-256-CBC
    // Only take the first 128 digits to get 512 bits
    vector = CryptoJS.AES.encrypt(wordArray, key, { iv: iv })
      .ciphertext.toString()
      .substring(0, 128);
  }

  // vector is 512 bits and is currently in hex
  return vector;
};

function wildCharString(len) {
  let output = "";
  for (let i = 0; i < len; i++) {
    let rand = getRndInteger(0, 255);
    if (rand !== 124) {
      output += String.fromCharCode(rand);
    } else {
      output += String.fromCharCode(255);
    }
  }
  return output;
}

export function getNewEncryptedPass(questions, answers, password) {
  let key = generateKey(answers);
  generateVerification(answers);

  // String of 64 characters, the password, two pipes, then the rest are wild characters
  let paddedPassword = wildCharString(62 - password.length);
  let rand = getRndInteger(0, 63 - password.length);
  paddedPassword =
    paddedPassword.substring(0, rand) +
    "|" +
    password +
    "|" +
    paddedPassword.substring(rand);

  let wordArrayPassword = CryptoJS.enc.Utf8.parse(paddedPassword);
  let wordArrayKey = CryptoJS.enc.Hex.parse(key);
  let iv = CryptoJS.enc.Base64.parse("9De0DgMTCDFGNokdEEial");

  // Encrypt using AES and store it locally
  return CryptoJS.AES.encrypt(wordArrayPassword, wordArrayKey, {
    iv: iv,
  }).toString();
}

export function bytesToString(bytes) {
  const denominations = ["KB", "MB", "GB", "TB"];
  // 1 KB = 2^10 bytes.
  // 1 MB = 2^20 bytes.
  // 1 GB = 2^30 bytes, and so on.
  const bitPlaces = Math.floor(Math.log2(bytes));
  let index = Math.floor(bitPlaces / 10) - 1;
  if (index < 0) {
    index = 0;
  } else if (index > 3) {
    index = 3;
  }
  const largestUnitInBytes = Math.pow(2, (index + 1) * 10);

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
  const formattedNumber = formatter.format(bytes / largestUnitInBytes);
  return `${formattedNumber} ${denominations[index]}`;
}
