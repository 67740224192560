import React, { useRef, useState } from "react";
import { Form, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useCookies } from "react-cookie";
import { verifyPassword } from "../util/SecureCommunication";
import BannerImage from "./BannerImage";
import FeedbackAlert from "./FeedbackAlert";
import LoadingButton from "./LoadingButton";
import "./CancelService.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { postWithSecureCredentials } from "../functions/securePost";
library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CancelService(props) {
  const passwordRef = useRef("");
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["cancelService"]);
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Verify Password
      try {
        const isValid = await verifyPassword(
          props.user.id,
          passwordRef.current.value,
          () => {
            navigate("/unlock");
          }
        );
        if (!isValid) {
          throw new Error("Password Incorrect");
        }
      } catch (e) {
        throw new Error("Password Incorrect");
      }

      await CancelSubscription(props.user.id);
      setFeedback({
        message: "Successfully Cancelled Service. Redirecting...",
        variant: "success",
        loading: true,
      });
      setTimeout(() => {
        navigate("/service-usage-info");
      }, 2000);
    } catch (e) {
      setFeedback({
        message: e.message,
        variant: "danger",
      });
    }
    setLoading(false);
  };

  const CancelSubscription = async (userId) => {
    return await postWithSecureCredentials(SERVER_URL + "CancelSubscription", {
      subscription: {
        user_id: userId,
      },
    })
      .then(() => {
        // These line wait till 2 mins to allow next cancellation.
        var date = new Date();
        date.setTime(date.getTime() + 60 * 2000);
        setCookie("cancelService", "yes", { expires: date, path: "/" });
      })
      .catch((err) => {
        throw new Error("Failed to cancel subscription");
      });
  };

  return (
    <Card
      body
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BannerImage />
      <h4 className="mt-3">
        <b>Cancel Subscription Service</b>
      </h4>
      <hr className="mt-0" />
      {cookies.cancelService === "yes" ? (
        <p>
          You have canceled your subscription recently. Please try again after
          some time.
        </p>
      ) : (
        <p>
          We're sorry to hear that you've decided to cancel your service. We
          wanted to let you know that any changes to your account will take
          effect at the beginning of the next billing cycle, which starts at the
          beginning of the<strong> next month</strong>. Please keep in mind that
          canceling your service means that you'll be returned to the
          <strong> FREE Service</strong>, which only allows you to claim and download
          files that have been shared with you. We appreciate your understanding and
          thank you for using our service.
        </p>
      )}

      <Form onSubmit={handleSubmit} className="w-100">
        <Form.Group id="sponsor_email" className="mb-3">
          <Row>
            <Form.Label column>Password</Form.Label>
            <Col xs={12} lg={9} xl={10}>
              <div className="pass-wrapper">
                <input
                  className="form-control"
                  type={passwordShown ? "text" : "password"}
                  ref={passwordRef}
                />
                <i className="test">
                  <FontAwesomeIcon
                    icon={passwordShown ? "eye-slash" : "eye"}
                    onClick={togglePassword}
                  />
                </i>
              </div>
            </Col>
          </Row>
        </Form.Group>
        <LoadingButton
          loading={loading}
          disabled={loading || cookies.cancelService === "yes"}
          type="submit"
          className="w-100"
        >
          Submit
        </LoadingButton>
      </Form>
      <FeedbackAlert feedback={feedback} className="mb-0 mt-2" />
    </Card>
  );
}
