import axios from "axios";
import {auth} from "../firebase";

async function getAuthUser() {
    const listen = () => new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            resolve(user);
            unsubscribe();
        });
    });
    return await listen();
}

export async function postWithSecureCredentials(link, data, config) {
    const user = await getAuthUser();
    if (!user) {
        return await axios.post(link, data, config);
    }
    const token = await user.getIdToken();
    if (!config) {
        config = {};
    }
    config.headers = { Authorization: `Bearer ${token}` };
    return await axios.post(link, data, config);
}

export async function getWithSecureCredentials(link, config) {
  const user = await getAuthUser();
  if (!user) {
      return await axios.get(link, config);
  }
  const token = await user.getIdToken();

  config.headers = { Authorization: `Bearer ${token}` };
  return await axios.get(link, config);
}