import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form
} from "react-bootstrap";
import {
  convertWordArrayToUint8Array,
  createUserKey,
  uint8ArrayToString,
  XOR,
} from "../functions/encoding";
import CryptoJS from "crypto-js";
import "./Open.css";
import {
  serverUrl,
  verifyPassword,
} from "../util/SecureCommunication";
import ComponentCard from "./ComponentCard.js";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FeedbackAlert from "./FeedbackAlert";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SERVER_URL } from "../assets/js/constants";
import { getUserFromEmail } from "../functions/getUser.js";
import { postWithSecureCredentials } from "../functions/securePost";
library.add(faEye, faEyeSlash);

function assertDefined(items) {
  let notDefined = [];
  Object.entries(items).forEach(([name, value]) => {
    if (value === undefined || value === null) {
      notDefined.push(name);
    }
  });
  if (notDefined.length !== 0) {
    throw Error(`${notDefined.join(",")} are not defined.`);
  }
}

async function onedriveShare(
  recipientEmail,
  key,
  keyRef,
  fileId,
  fileName,
  user,
  accessToken,
  res_recipId,
  pushFeedback = () => { },
  copyFileId = ""
) {
  try {
    assertDefined({ fileId });
    if (keyRef === undefined) {
      throw Error("keyRef is not defined.");
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `The URL you have accessed contains some missing information.`,
    });
    return;
  }
  const pageUrl = process.env.REACT_APP_FRONTEND_URL;
  const invitation = {
    recipients: [
      {
        email: recipientEmail,
      },
    ],
    requireSignIn: true,
    roles: ["read", "write"],
    message: "Please join me in editing this file",
    sendInvitation: true,
  };
  var temp = JSON.stringify(invitation);

  const afterShare = (res) => {
    // construct email subject and body
    const keyStr = uint8ArrayToString(key);
    const subject = `Cynorix Secure File Sharing: New file "${fileName}"`;

    let searchParams = new URLSearchParams();
    let emailBody = "";
    searchParams.append("fileId", fileId);
    searchParams.append("key", keyStr);
    searchParams.append("owner", user.email);
    searchParams.append("recip", recipientEmail);
    searchParams.append("keyRef", keyRef);
    let receiveUrl = `${pageUrl}receive/?${searchParams.toString()}`;

    postWithSecureCredentials(`${serverUrl}updateClaimUrl`, {
        recip_id: res_recipId,
        file_id: fileId,
        recip_url: receiveUrl,
      })
      .then((res) => {
        postWithSecureCredentials(`${serverUrl}increaseShareCounter`, {
            userId: user.id,
          })
          .then((res) => { })
          .catch((err) => { });
      })
      .catch((err) => { });

    if (keyRef === null) {
      emailBody = `
          ${user.email} has shared the following file: <b> ${fileName}</b>
            <br>
            Please click <a id="sign-in" href="${receiveUrl}">here</a> 
            to claim the file with Cynorix Secure File Sharing
            <br>
          `;
    } else {
      emailBody = `
            ${user.name} would like to share a file with you. 
            Please follow the first link to install Cynorix Secure File Sharing 
            to your Microsoft Onedrive
            <br>
            ${pageUrl}set-password
            <br>
            Once installed, click <a id="sign-in" href="${receiveUrl}">here</a> 
            to claim the file
            <br>
          `;
    }
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Sending email notification to recipient...`,
    });
    var data = {
      message: {
        subject: subject,
        body: {
          contentType: "HTML",
          content: emailBody,
        },
        toRecipients: [
          {
            emailAddress: {
              address: recipientEmail,
            },
          },
        ],
      },
    };
    data = JSON.stringify(data);
    axios
      .post("https://graph.microsoft.com/v1.0/me/sendMail", data, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        pushFeedback({
          variant: "success",
          message: `File has been shared!`,
        });
      })
      .catch((err) => {
        pushFeedback({
          variant: "danger",
          message: `An error occurred while sending the email notification.`,
        });
      });
  };

  const shareFile = () => {
    const oldFileId = fileId;
    if (copyFileId && copyFileId !== "") {
      fileId = copyFileId;
    }
    return axios
      .post(
        "https://graph.microsoft.com/v1.0/me/drive/items/" + fileId + "/invite",
        temp,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        fileId = oldFileId;
        afterShare(res);
      })
      .catch((err) => {
        pushFeedback({
          variant: "danger",
          message: `An error occured while sharing the file. Please make sure the given user has an active OneDrive account.`,
        });
      });
  };

  shareFile().catch((err) => {
    axios
      .get(`https://graph.microsoft.com/v1.0/me/drive/sharedWithMe`, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        const filesSharedWithMe = res.data.value;
        let hasFile = false;
        let sharedWithMeDriveId = filesSharedWithMe[0].parentReference.driveId;

        var fileNameOnOnedrive = fileName.replace(/:/g, "_");
        fileNameOnOnedrive = fileNameOnOnedrive.replace(/\//g, " ");
        for (let i = 0; i < filesSharedWithMe.length; ++i) {
          if (
            filesSharedWithMe[i].remoteItem.id === fileId ||
            filesSharedWithMe[i].remoteItem.name === fileNameOnOnedrive
          ) {
            hasFile = true;
            const oldFileId = fileId;
            fileId = filesSharedWithMe[i].id;
            let rootFolderId = "";

            const getOneDriveRootFolder = async () => {
              const endpoint = "https://graph.microsoft.com/v1.0/me/drive/root";
              const response = await axios.get(endpoint, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              rootFolderId = response.data.id;
            };

            // Create a copy of the shared file in User B's My Drive
            // eslint-disable-next-line no-loop-func
            getOneDriveRootFolder().then(async () => {
              const createCopyEndpoint = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/copy`;
              const createCopyPayload = {
                parentReference: {
                  driveId: sharedWithMeDriveId,
                  id: rootFolderId,
                },
                name: filesSharedWithMe[i].name,
              };
              const copyResponse = await axios.post(
                createCopyEndpoint,
                createCopyPayload,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                  },
                }
              );
              const copyStatusUrl = copyResponse.headers["location"];

              // Wait for the copy operation to complete
              let copyStatusResponse;
              do {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                copyStatusResponse = await axios
                  .get(copyStatusUrl)
                  .catch((err) => {
                    if (
                      err.response.data.errorCode.includes(
                        "RelationshipNameAlreadyExists"
                      )
                    ) {
                      pushFeedback({
                        variant: "danger",
                        message: `You already have a file named ${fileName} in the Onedrive root folder. Please rename the file.`,
                      });
                    }
                  });
              } while (
                copyStatusResponse.data.status === "inProgress" ||
                copyStatusResponse.data.status === "notStarted"
              );

              const copyDriveItemId = copyStatusResponse.data.resourceId;

              const endpoint = `https://graph.microsoft.com/v1.0/me/drive/items/${copyDriveItemId}/invite`;
              const requestBody = {
                requireSignIn: true,
                sendInvitation: true,
                roles: ["write"],
                recipients: [
                  {
                    email: recipientEmail,
                  },
                ],
                message: "Please join me in editing this file",
              };

              await axios
                .post(endpoint, requestBody, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  postWithSecureCredentials(SERVER_URL + "changeFileID", {
                    userId: user.id,
                    fileId: oldFileId,
                    newId: copyDriveItemId,
                  });
                  fileId = oldFileId;
                  copyFileId = copyDriveItemId;
                  afterShare(res);
                });
            });
          }
        }
        if (!hasFile) {
          pushFeedback({
            variant: "danger",
            message: `It seems like this file isn't on your Onedrive...`,
          });

          postWithSecureCredentials(`${serverUrl}removeFailedShare`, {
              senderId: user.id,
              fileId: fileId,
              recipId: res_recipId,
              recipEmail: recipientEmail,
            })
            .then((res) => { })
            .catch((err) => { });
        }
      });
  });
}

async function recipientInSystem(recipientEmail) {
  return new Promise((resolve, reject) => {
    postWithSecureCredentials(`${serverUrl}checkForUser`, {
        recipientEmail,
      })
      .then((res) => {
        resolve(res.data.data.exists);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function shareFileOnedrive(
  recipientEmail,
  pwd,
  fileId,
  accessToken,
  user,
  pushFeedback = () => { },
  redirectIfLocked = () => { },
  copyFileId = ""
) {
  // preliminary checks:
  let recipExists;
  try {
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Verifying Password for ${user.name}`,
    });
    const isPwdValid = await verifyPassword(user.id, pwd, redirectIfLocked);

    if (!isPwdValid) {
      pushFeedback({
        variant: "danger",
        message: `Password incorrect for user ${user.email} .`,
      });
      return;
    }

    recipExists = await recipientInSystem(recipientEmail);
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An error occurred while verifying your password and
           verifying if the recipient is a cynorix user.`,
    });
  }
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Sharing file...`,
  });

  // encrypt pwd
  const userKey = createUserKey(fileId, pwd, user.id);
  const randomKey1 = convertWordArrayToUint8Array(
    CryptoJS.lib.WordArray.random(32)
  );
  const randomKey2 = convertWordArrayToUint8Array(
    CryptoJS.lib.WordArray.random(32)
  );
  const rk1XORrk2 = XOR(randomKey1, randomKey2);
  const ukXORrk1 = XOR(userKey, randomKey1);

  await axios.post(`${serverUrl}addShareKeys`, {
      recipientEmail: recipientEmail,
      fileId: fileId,
      encRandKey: rk1XORrk2,
      encHashedKey: ukXORrk1,
      recipExists: recipExists,
      userId: user.id,
    })
    .then(async (res) => {
      const keyRef = res.data.data.shareKeyRef;
      const fileName = res.data.data.fileName;
      const res_recipId = res.data.data.res_recipId;

      if (res.data.data.reshare === true) {
        pushFeedback({
          variant: "warning",
          message: `This file has already been shared with this person.`,
        });
        return false;
      }
      await onedriveShare(
        recipientEmail,
        randomKey2,
        keyRef,
        fileId,
        fileName,
        user,
        accessToken,
        res_recipId,
        pushFeedback,
        copyFileId
      );
    })
    .catch(async (err) => {
      const msg = err.response.data.errormsg;
      const ERR_SHARE_CAPPED = "account capped";
      if (msg === ERR_SHARE_CAPPED) {
        const sponsorId = (await getUserFromEmail(user.email)).userData.sponsor_id;
        if (!sponsorId) {
        pushFeedback({
          variant: "danger",
          message: (
            <>
              You have reached your limit on file sharing.{" "}
              <Link to="/select-chunks">Buy More Chunks</Link> or{" "}
              <Link to="/show-service-class">Upgrade Your Plan</Link>
            </>
          ),
        });
        } else {
          pushFeedback({
            variant: "danger",
            message: (
              <>
                Your sponsorship has reached its limit on file sharing.{" "}
                Please ask your sponsor to purchase more shares.
              </>
            ),
          });

        }
      } else {
        pushFeedback({
          variant: "danger",
          message: `An error occurred while sharing.`,
        });
      }
    });
}

export default function Open(props) {
  const [shareFormFeedback, setShareFormFeedback] = useState(null);
  const [cookies] = useCookies(["accessToken"]);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const [user, setUser] = useState(props.user);
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // update name of file to open based on fileId in url
  useEffect(() => {
    const fileId = searchParams.get("fileId");
    postWithSecureCredentials(`${serverUrl}getFileName`, { userId: user.id, fileId: fileId })
      .then((res) => {
      });
  }, [searchParams, user.id]);

  // Card.Headers are commented out in favour of bigger h4 Headers
  // in the body, but can be changed.

  const helpContent = (
    <p className="mb-1">
      Users can share files with anyone with a valid email. An email will be automatically
      sent, including information to claim the file and sign up for an account if the recipient does not have one.
    </p>
  );

  return (
    <ComponentCard title="Share File" helpContent={helpContent}>
      <hr className="mt-0" />
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          if (e.currentTarget.checkValidity() === false) {
            let message = "Invalid Fields: ";
            if (e.target.recipientEmail.checkValidity() === false) {
              message += "Please enter a valid email. ";
            }
            if (e.target.accountPass.checkValidity() === false) {
              message += "Please provide a password. ";
            }
            setShareFormFeedback({
              message,
              variant: "danger",
            });
            e.stopPropagation();
          } else {
            // call API here and handle response
            shareFileOnedrive(
              e.target.recipientEmail.value.toLowerCase(),
              e.target.accountPass.value,
              searchParams.get("fileId"),
              cookies.accessToken,
              user,
              (feedback) => {
                if (feedback && feedback.message === "File has been shared!") {
                  e.target.recipientEmail.value = "";
                }
                setShareFormFeedback(feedback);
              },
              () => {
                navigate("/unlock");
              }
            );
          }
        }}
      >
        <Form.Group className="mb-3 row" controlId="share-recip-email">
          <Form.Label column>Recipient Email</Form.Label>
          <Col xs={12} lg={9} xl={10}>
            <Form.Control
              name="recipientEmail"
              type="email"
              placeholder="Email"
              required
            />
          </Col>
          <Form.Control.Feedback type="invalid">
            Please fill in a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 row" controlId="share-account-password">
          <Form.Label column>Your Password</Form.Label>
          <Col xs={12} lg={9} xl={10}>
            <div className="pass-wrapper">
              <Form.Control
                className="form-control"
                type={passwordShown ? "text" : "password"}
                name="accountPass"
                placeholder="Password"
                required
              />
              <i className="test">
                <FontAwesomeIcon
                  icon={passwordShown ? "eye-slash" : "eye"}
                  onClick={togglePassword}
                />
              </i>
            </div>
          </Col>
          <Form.Control.Feedback type="invalid">
            Please fill in your password.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          aria-label="Share File"
          className="w-100"
          variant="primary"
          disabled={
            shareFormFeedback !== null && shareFormFeedback.loading === true
          }
        >
          Share
        </Button>
        <FeedbackAlert feedback={shareFormFeedback} className="mt-2" />
      </Form>
    </ComponentCard>
  );
}
