import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCsckdCy9x78zjKv0Y_aO7UGcurhzVdCqs",
  authDomain: "cynorix-onedrive-production.firebaseapp.com",
  databaseURL: "https://cynorix-onedrive-production-default-rtdb.firebaseio.com",
  projectId: "cynorix-onedrive-production",
  storageBucket: "cynorix-onedrive-production.appspot.com",
  messagingSenderId: "412749143663",
  appId: "1:412749143663:web:dd40b1e8a2cdc2c790f79a",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
