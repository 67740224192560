import React, { Fragment, useState, useEffect, useRef } from "react";
import SecurityQuestionCheck from "./SecurityQuestionCheck";
import { Button, Row, Col, Form, Modal, Collapse } from "react-bootstrap";
import axios from "axios";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import background from "../../assets/images/background-black.png";
import {
  generateVerification,
  getNewEncryptedPass,
} from "../../functions/encoding";
import { formCheck, XOR, hashString } from "../../functions/encoding";
import { useNavigate } from "react-router-dom";
import FeedbackAlert from "../FeedbackAlert";
import "./ChangePassword.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../LoadingButton";
import PasswordWrapper from "../PasswordWrapper";
import { postWithSecureCredentials, getWithSecureCredentials } from "../../functions/securePost";
library.add(faEye, faEyeSlash);

const firebaseAddress = process.env.REACT_APP_SERVER_URL;


export default function VerifyCoupon(props) {
  const navigate = useNavigate();
  const couponRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordNShown, setPasswordNShown] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [isDiscounted, setIsDiscounted] = useState(false);


  useEffect(() => {
    console.log("display name", props.user.name)
    // Fetch the flag from the server when the component mounts
    getWithSecureCredentials(`${firebaseAddress}isUserDiscounted`, {})
      .then((res) => {
        console.log(res.data.couponFlag)
        setIsDiscounted(res.data.couponFlag);
        if (res.data.couponFlag) {
          setFeedback({
            loading: false,
            message: "You are already on a discounted tier.",
            variant: "danger",
          });
        }

      })
      .catch((err) => {
        console.error("Error fetching discounted flag:", err);
      });
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    postWithSecureCredentials(`${firebaseAddress}verifyCode`, {
      couponCode: couponRef.current.value,
      displayName: props.user.name
    })
      .then((res) => {
        setFeedback({
          loading: true,
          message: "Coupon verified",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/upgrade-service");
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setFeedback({
          message: "Coupon not verified",
          variant: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const verifyBody = (
    <Fragment>
      <Form
        noValidate
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3 row">
          <Form.Label column className="text-center text-lg-end px-0">
            Coupon
          </Form.Label>
          <Col xs={12} lg={10}>
            <Form.Control type={"text"}
              ref={couponRef}
              required
              placeholder="**********"
            />
          </Col>
        </Form.Group>

        <LoadingButton
          loading={feedback && feedback.loading}
          type="submit"
          className="w-100"
          disabled={isDiscounted}
        >
          Verify
        </LoadingButton>
        <FeedbackAlert feedback={feedback} className="mt-2 mb-0" />
      </Form>
    </Fragment>
  );

  return (
    <Fragment>
      <ManageAccountOptsPage
        title="Verify Coupon"
        background={background}
        body={verifyBody}
      />
    </Fragment>
  );
}
