import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function AccessDeniedModal() {
  const navigate = useNavigate();

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Access Denied</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are not authorized to visit this page. <br />
          <br />
          Please sign in as the authorized user to access this page.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </Button>
        <Button
          onClick={() => {
            navigate("/home");
          }}
        >
          Go Home
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
