import React from "react";
import {
  Card,
  Row,
  Col
} from "react-bootstrap";
import BannerImage from "../BannerImage";

export default function ManageAccountOptsPage(props) {
  return (
    <Card
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Card.Body>
        <BannerImage />
        <h4 className="mt-3">
          <b>{props.title}</b>
        </h4>
        <hr className="mt-0" />
        <Row>
          <Col>{props.body}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
