import axios from "axios";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import ComponentCard from "./ComponentCard.js";
import "./new.css";
import "./new.css";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import FileUploadBox from "../components/FileUploadBox";
import { verifyPassword } from "../util/SecureCommunication";
import CryptoJS from "crypto-js";
import {
  bytesToString,
  convertWordArrayToUint8Array,
  createUserKey,
  encodeReq,
  formCheck,
  XOR,
} from "../functions/encoding";
import FeedbackAlert from "./FeedbackAlert";
import FeedbackProgressBar from "./FeedbackProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEye,
  faEyeSlash,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { getUserFromEmail } from "../functions/getUser.js";
import { postWithSecureCredentials } from "../functions/securePost";
library.add(faEye, faEyeSlash, faCircleInfo);

const serverUrl = process.env.REACT_APP_SERVER_URL;
const USING_ONEDRIVE = true;

export default function New(props) {
  const [files, setFiles] = useState([]);
  const isFilesEmpty = files.length === 0;

  const [hasFreeTrial, setHasFreeTrial] = useState(false);
  const [isFileLimitCapped, setIsFileLimitCapped] = useState(false);
  const [isUnpaidUser, setIsUnpaidUser] = useState(false);
  const [cookies] = useCookies(["accessToken"]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isSponsored, setIsSponsored] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const [feedback, setFeedback] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(null);

  const [user, setUser] = useState(props.user);
  useEffect(() => {
    async function checkFileLimit(signal) {
      const userData = (await getUserFromEmail(props.user.email)).userData;
      if (userData.freetrial === "active") {
        setHasFreeTrial(true);
      }
      if (userData.sponsor_id) {
        setIsSponsored(true);
      }
      await postWithSecureCredentials(
        `${serverUrl}checkFileLimit`,
        {
          userId: props.user.id,
          sponsorId: userData.sponsor_id ? userData.sponsor_id : props.user.id,
        },
        { signal }
      )
        .then((res) => {
          const data = res.data.data;
          setIsFileLimitCapped(res.data.data.fileLimit_capped);
          setFileData({
            count: data.fileCount,
            limit: data.fileLimit,
          });
          console.log("Is sponsored " + isSponsored + " " + userData.sponsor_id);
          if (
            res.data.data.fileLimit === 0 &&
            res.data.data.fileShare === 0 &&
            isSponsored === false
          ) {
            setIsUnpaidUser(true);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.code !== "ERR_CANCELED") {
          }
        });
    }

    if (props.user) {
      setUser(props.user);
      const abort = new AbortController();
      checkFileLimit(abort.signal);

      return () => {
        abort.abort();
      };
    }
  }, [props.user, isSponsored]);
  useEffect(() => {}, [user]);
  const navigate = useNavigate();

  const [fileData, setFileData] = useState({
    count: 0,
    limit: 0,
  });
  function removeFile(index) {
    setFiles(files.filter((v, i) => i !== index));
  }

  async function checkFileLimit(signal) {
    const userData = (await getUserFromEmail(props.user.email)).userData;
    if (userData.sponsor_id) {
      setIsSponsored(true);
    }
    await postWithSecureCredentials(
      `${serverUrl}checkFileLimit`,
      {
        userId: userData.sponsor_id ? userData.sponsor_id : props.user.id,
      },
      { signal }
    )
      .then((res) => {
        const data = res.data.data;
        setIsFileLimitCapped(res.data.data.fileLimit_capped);
        setFileData({
          count: data.fileCount,
          limit: data.fileLimit,
        });
        // console.log(isSponsored + " " + userData.sponsor_id)
        if (
          res.data.data.fileLimit === 0 &&
          res.data.data.fileShare === 0 &&
          isSponsored === false
        ) {
          setIsUnpaidUser(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code !== "ERR_CANCELED") {
        }
      });
  }

  async function encryptAndUploadFile(
    uploadName,
    files,
    password,
    pushFeedback = () => {},
    pushDownloadProgress = () => {},
    redirectIfLocked = () => {}
  ) {
    if (USING_ONEDRIVE) {
      return encryptAndUploadFileOnedrive(
        uploadName,
        files,
        password,
        pushFeedback,
        pushDownloadProgress,
        redirectIfLocked
      );
    }
  }

  /**
   * Converts a CryptoJS WordArray into an 8 bit array
   * @param {WordArray} wa - 8 bit data encoded into a CryptoJS word array (32 bit words)
   * @returns {Uint8Array} - 8 bit array containing the hex data
   */
  function wordArrayToBytes(wa) {
    let bytes = [];
    for (var i = 0; i < wa.sigBytes; ++i) {
      var j = 24 - (i % 4) * 8;
      bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
    }
    return new Uint8Array(bytes);
  }

  async function encryptAndUploadFileOnedrive(
    uploadName,
    files,
    password,
    pushFeedback = () => {},
    pushDownloadProgress = () => {},
    redirectIfLocked = () => {}
  ) {

    console.log("Start")
    // create an instance of JSZip
    let zip = new JSZip();
    // add all the files to the zip
    files.forEach((file) => {
      zip.file(file.name, file);
    });

    let blob, zipFile;
    try {
      pushFeedback({
        variant: "info",
        loading: true,
        message: `Verifying Password for ${user.name}`,
      });

      const isPwdValid = await verifyPassword(
        user.id,
        password,
        redirectIfLocked
      );

      if (isPwdValid !== true) {
        pushFeedback({
          variant: "warning",
          message: `Password incorrect for user ${user.name}(${user.email}).`,
        });
        return;
      }
      pushFeedback({
        variant: "info",
        loading: true,
        message: `Compressing contents...`,
      });
      const blob = await zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        streamFiles: true,
      }, function updateCallback(metadata) {
        if (Math.random() > 0.01) return; //Slows callbacks down
        pushDownloadProgress({
          loading: true,
          now: Math.round(metadata.percent),
        });
      });

      zipFile = new File([blob], `${uploadName}.zip`, {
        type: "blob",
        compression: "DEFLATE",
      });
    } catch (err) {
      pushFeedback({
        variant: "danger",
        message: `A problem occurred when trying to verify your password and
                  compressing the files.`,
      });
      return;
    }
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Encrypting file...`,
    });

    //bypass react optimization
    const delay = () => new Promise((resolve) => setTimeout(resolve,0));
    await delay();

    // upload file using doUpload
    const randomKey = CryptoJS.lib.WordArray.random(32);
    const iv = CryptoJS.enc.Hex.parse(
      CryptoJS.lib.WordArray.random(16).toString()
    );

    const reader = new FileReader();

    reader.onload = async () => {
      const file = reader.result;

      //create file signature
      const fileSig = CryptoJS.SHA256(file);

      let aesEnc = CryptoJS.algo.AES.createEncryptor(randomKey, {
        mode: CryptoJS.mode.CFB,
        iv: iv,
      });

      const SLICE_SIZE = 10 * 1024 * 1024;

      let start = 0;
      let encryptedBlobParts = [];

      encryptedBlobParts.push(new Blob([wordArrayToBytes(iv)]));

      while (start < zipFile.size) {
        let wordArrayInput = CryptoJS.lib.WordArray.create(file.slice(start, start + SLICE_SIZE));
        if (start + SLICE_SIZE >= zipFile.size) {
          wordArrayInput.concat(fileSig);
        }
        let encryptedSlice = aesEnc.process(wordArrayInput);

        encryptedBlobParts.push(new Blob([wordArrayToBytes(encryptedSlice)]));

        start += SLICE_SIZE;

        pushDownloadProgress({
          loading: true,
          now: Math.round((start / zipFile.size) * 100)
        });
        await delay();
      }
      
      encryptedBlobParts.push(new Blob([wordArrayToBytes(aesEnc.finalize())]));

      const encryptedFileBlob = new Blob(encryptedBlobParts, { type: "application/octet-stream" });
      const encryptedFileName = `${zipFile.name}.enc`;
      const encryptedFile = new File([encryptedFileBlob], encryptedFileName);

      const metadata = {
        name: encryptedFileName,
        mimeType: "application/octet-stream; charset=UTF-8",
      };

      // create data as FormData for upload
      const formData = new FormData();
      // add association key values
      formData.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], {
          type: "application/json; charset=UTF-8",
        })
      );
      formData.append("file", encryptedFile);
      formData.append("upload_file", true);

      pushFeedback({
        variant: "info",
        loading: true,
        message: `Uploading file to Onedrive...!`,
      });
      pushDownloadProgress({
        loading: true,
        now: 0
      });

      console.log("Starting uploading ....")

      console.log("checking permissions")

      // Check permissions
      axios.get('https://graph.microsoft.com/v1.0/me/drive', {
        headers: {
          Authorization: `Bearer ${cookies.accessToken}`,
        },
      })
      .then(response => {
        console.log('Drive details:', response.data);
      })
      .catch(error => {
        console.error('Error fetching drive details:', error);
      });

      var tempName = encryptedFileName.replace(/:/g, "_");
      tempName = tempName.replace(/\//g, " ");
      console.log("token", cookies.accessToken)
      axios
        .post(
          "https://graph.microsoft.com/v1.0/me/drive/root:/" +
            tempName +
            ":/createUploadSession",
          {
            item: {
              "@microsoft.graph.conflictBehavior": "rename",
              fileSize: encryptedFile.size,
              "name": tempName 
            },
          },
          {
            headers: {
              Authorization: "Bearer " + cookies.accessToken,
              "Content-Type": "application/json; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          console.log("Result of uploading", res)
          axios
            .put(res.data.uploadUrl, encryptedFile, {
              headers: {
                Authorization: "Bearer " + cookies.accessToken,
                "Content-Type": "application/octet-stream; charset=UTF-8",
              },
              onUploadProgress: function(progressEvent) {
                pushDownloadProgress({
                  loading: true,
                  now: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                });
              }
            })
            .then((res) => {
              console.log("data upload",res)
              sendTransformed(
                res.data.id,
                encryptedFileName,
                encryptedFile.size,
                password,
                randomKey,
                user,
                pushFeedback
              );
            })
            .catch((err) => {
              console.log("error while uploading", err)
              pushFeedback({
                variant: "danger",
                message: `An error occurred while uploading the file contents.`,
              });
            });
        })
        .catch((err) => {
          console.log("error in drive", err)
          pushFeedback({
            variant: "danger",
            message: `An error occurred. Please log into OneDrive to make sure your account is set`,
          });
        });
    };

    reader.readAsArrayBuffer(zipFile);
  }

  function sendTransformed(
    fileId,
    fileName,
    fileSize,
    pwd,
    randomKey,
    user,
    pushFeedback = () => {}
  ) {
    const userKey = createUserKey(fileId, pwd, user.id);
    const randomKeyAsBytes = convertWordArrayToUint8Array(randomKey);
    const userKeyXORRandomKey = XOR(userKey, randomKeyAsBytes);
    const passCheck = formCheck(pwd + user.id);
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Saving Encryption Key...`,
    });

    postWithSecureCredentials(
      `${serverUrl}addTransformedKey`,
      encodeReq(
        {
          fileId: fileId,
          transformed: userKeyXORRandomKey,
          fileName: fileName,
          fileSize: fileSize,
          toCheck: passCheck,
          userId: user.id,
        },
        user.email
      )
    )
      .then((res) => {
        pushFeedback({
          variant: "success",
          message: "Successfully uploaded file!",
        });
        navigate({
          pathname: `/open`,
          search: `?${createSearchParams({
            fileId,
            userId: user.id,
          })}`,
        });
      })
      .catch((err) => {
        pushFeedback({
          variant: "danger",
          message: `An unexpected error occurred while saving file key.`,
        });
      });
  }
  const helpContent = (
    <p className="mb-1">
      Users can select one or more files from their computer for encryption by
      clicking on or dropping files in the big, dotted rectangle. Selected files
      are compressed and gathered in a single zipped folder. This folder is then
      added to the user's OneDrive and listed under their file table.
    </p>
  );
  return (
    <ComponentCard
      title="Encrypt and Upload to OneDrive"
      helpContent={helpContent}
    >
      {!isUnpaidUser && isFileLimitCapped && !isSponsored ? (
        <Alert variant="danger" className="text-center">
          You have reached your
          <br />
          <h6>File Storage Limit </h6>
          <div>
            {hasFreeTrial ? (
              ""
            ) : (
              <Link to="/select-file-chunks">Buy More Encryptions,</Link>
            )}{" "}
            <Link to="/show-service-class">Upgrade Your Plan</Link> or delete
            files from your <Link to="/sheets">File Table</Link>.
          </div>
        </Alert>
      ) : null}
      {isSponsored && isFileLimitCapped ? (
        <Alert variant="danger" className="text-center">
          Your sponsorship has reached its
          <br />
          <h6>File Storage Limit. </h6>
          <div>
            Ask your sponsor to purchase more chunks
            <br /> or delete files from your{" "}
            <Link to="/sheets">File Table</Link>.
          </div>
        </Alert>
      ) : null}
      {isUnpaidUser && !isSponsored ? (
        <Alert variant="danger">
          You have not subscribed to Cynorix fileshare yet.
          <br />
          <div>
            <Link to="/free-trial">Try our free trial</Link>
            &nbsp;or&nbsp;
            <Link to="/show-service-class">Upgrade Your Plan</Link>
          </div>
        </Alert>
      ) : null}
      <Row>
        <Col md={12} sm={12} style={{ marginBottom: 10 }}>
          <Card
            className="Row"
            style={{
              boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <FileUploadBox
              className="Row"
              handleFileUpload={(uploads) => {
                // uploads is a FileList, iterate and add to files
                const uploadArray = [];
                for (const file of uploads) {
                  uploadArray.push(file);
                }
                setFiles(files.concat(uploadArray));
              }}
              style={{
                minHeight: 120,
                height: files.length > 0 ? 120 : "100%",
                display: "flex",
                borderRadius: 10,
                margin: 10,
                border: "2px dashed gray",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card.Text>Click to add or drop files here...</Card.Text>
            </FileUploadBox>
            {/* List of uploaded files*/}
            <Card.Body
              style={{
                display: files.length > 0 ? null : "none",
              }}
            >
              <span
                className="ms-3"
                style={{
                  color: "#424242",
                }}
              >
                <strong>Files Uploaded</strong>
              </span>
              <ListGroup
                style={{
                  maxHeight: "20vh",
                  overflow: "scroll",
                  width: "100%",
                }}
              >
                {files.map((file, index) => {
                  return (
                    <ListGroup.Item key={index}>
                      {`${file.name} - ${bytesToString(file.size)}`}
                      <CloseButton
                        style={{ float: "right" }}
                        aria-label="Remove Upload"
                        onClick={(e) => {
                          removeFile(index);
                        }}
                      />
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        {/* The Encrypt Form */}
        <Col md={12} sm={12}>
          {/* This is currently hard coded like the original...*/}
          <Form
            noValidate
            // validated={isFormValidated}
            onSubmit={(e) => {
              e.preventDefault();
              if (e.currentTarget.checkValidity() === false) {
                if (e.target.accountPass.value === "") {
                  setFeedback({
                    variant: "warning",
                    message: `Please enter your password.`,
                  });
                }
                e.stopPropagation();
              } else {
                encryptAndUploadFile(
                  e.target.uploadName.value,
                  files,
                  e.target.accountPass.value,
                  setFeedback,
                  setDownloadProgress,
                  () => {
                    navigate("/unlock");
                  }
                );
              }
            }}
          >
            <fieldset disabled={isFileLimitCapped}>
              <Form.Group
                as={Row}
                controlId="upload-name"
                className="mb-3 align-items-center justify-content-space-around"
              >
                <Form.Label column sm={2}>
                  Package Name
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    placeholder="Name of Upload"
                    name="uploadName"
                    defaultValue={new Date().toLocaleString()}
                    required
                  />
                </Col>
                <Col sm={1}>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={
                      <Popover>
                        <Popover.Body>
                          {
                            "This name will be assigned to the encrypted package. By default, it is set to the current time."
                          }
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="help-icon"
                    />
                  </OverlayTrigger>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="upload-pass"
                className="mb-3 align-items-center"
              >
                <Form.Label column sm={2}>
                  Password
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type={passwordShown ? "text" : "password"}
                    name="accountPass"
                    placeholder="Password"
                    required
                  />
                </Col>
                <Col sm={1}>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={
                      <Popover>
                        <Popover.Body>
                          {
                            "For your security, you must confirm your password before we can begin the encryption process."
                          }
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="help-icon"
                    />
                  </OverlayTrigger>
                </Col>
              </Form.Group>
              <Button
                type="submit"
                className="mb-2"
                disabled={isFilesEmpty || (feedback && feedback.loading)}
                style={{ width: "100%" }}
              >
                Submit
              </Button>
              <FeedbackAlert feedback={feedback} />
              <FeedbackProgressBar downloadProgress={downloadProgress} />
            </fieldset>
          </Form>
        </Col>
      </Row>
      <hr className="my-2" />
      <span>
        {fileData.count} {fileData.count === 1 ? "file" : "files"} -{" "}
        {fileData.limit ? fileData.limit : 0} file limit
      </span>
    </ComponentCard>
  );
}
