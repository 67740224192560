import React from "react";
import { Card, Button, Stack, Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import video from "../assets/video/secure-file-sharing-google-microsoft.mp4";

const featuresList = [
  {
    heading: "No password sharing required",
    description:
      "Unlike other encryption tools, users do not have to share their passwords or keys with others. Each user only needs their own password.",
  },
  {
    heading: "Multi-factor authentication",
    description:
      "Cynorix provides users with several forms of authentication for their convenience and superior security.",
  },
  {
    heading: "Expiry dates",
    description:
      "Files are only available to claim within a certain time window, adding an extra level of security.",
  },
  {
    heading: "Private Encryption",
    description:
      "Encryption is end to end, with the encryption key formed solely within an end-user's trusted device.",
  },
  {
    heading: "Notifications",
    description:
      "Emails are sent to users when changes are made to their account or files, so they are always informed.",
  },
  {
    heading: "Ease of Use",
    description:
      "A simple to understand tool that removes the headaches of securely sharing files.",
  },
];

export default function Home() {
  const navigate = useNavigate();

  const features = featuresList.map(({ heading, description }) => {
    return (
      <div
        style={{
          flex: "1 400px",
        }}
        className="m-1"
      >
        <Card body className="w-100 h-100">
          <h6 className="text-uppercase small">
            <b>{heading}</b>
          </h6>
          <p class="feature-desc">{description}</p>
        </Card>
      </div>
    );
  });

  const quickLinks = [
    {
      name: "Get Started",
      action: () => {
        navigate("/new");
      },
    },
    {
      name: "User Guide",
      action: () => {
        const link = document.createElement("a");
        link.download = "OneDrive SFS User Guide";
        link.href = "/od_sfs_manual.pdf";
        link.click();
      },
    },
    {
      name: "Contact Us",
      action: () => {
        navigate("/contact");
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          minHeight: "40vh",
          backgroundImage: `url(/img/CYN_Cynorix.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxShadow: "inset 0px 0px 15px 5px rgba(0, 0, 0, 0.5)",
          margin: "0 auto",
        }}
        className="py-4 mb-4"
      >
        <Container style={{ height: "100%" }}>
          <Row className="align-items-center h-100">
            <Col xs={12} sm={12} md={6} lg={5} className="justify-items-center">
              <Card
                className="py-5 px-4"
                style={{
                  border: "none",
                  backgroundColor: "rgba(255, 255, 255, 0.95)",
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <h3 className="mb-3">
                  <b>Secure File Sharing</b>
                </h3>
                <Stack gap={2}>
                  {quickLinks.map(({ name, action }) => (
                    <Button className="w-100" onClick={action}>
                      {name}
                    </Button>
                  ))}
                </Stack>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mb-2">
          <Col>
            <h4>
              <b>
                Why is it so{" "}
                <span style={{ color: "#951212" }}>
                  <u>Great</u>?
                </span>
              </b>
            </h4>
          </Col>
        </Row>
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          {features}
        </div>
        <Row className="mt-3 mb-2">
          <h4>
            <b>About Secure File Sharing</b>
          </h4>
        </Row>
        <Row className="my-2">
          {/* Video  */}
          <Col xs={12}>
            <Card>
              <video controls>
                <source src={video} type="video/mp4"></source>
              </video>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
