import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./HowToUse.css";

function HowToUse() {
  return (
    <Container className="text-light show-service-class-title mb-1">
      <Row className="mb-4">
        <h1>How to Use Cynorix Secure File Sharing</h1>
      </Row>
      <Row className="mb-2">
        <h3>Creating a New Account</h3>
      </Row>
      <Row className="mb-2">
        <h3>Activating Your Free Trial</h3>
      </Row>
      <Row className="mb-2">
        <h3>Upgrading to Our Paid Service</h3>
      </Row>
      <Row className="mb-2">
        <h3>Encrypting Files</h3>
      </Row>
      <Row className="mb-2">
        <h3>Decrypting Files</h3>
      </Row>
    </Container>
  );
}

export default HowToUse;

