import {
  getAuth,
  reauthenticateWithPopup,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WEB_URL = process.env.REACT_APP_FRONTEND_URL;

getAuth().onAuthStateChanged((user) => {
  if (user) {
    axios
      .post(`${SERVER_URL}generateKey`, {
        userExists: true,
        userEmail: user.email,
        pbValidVecBase64: null,
      })
      .then((res) => {})
      .catch((err) => {});
  } else {
  }
});

export default function useMicrosoftAuth() {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let accessToken = getCookie("accessToken");
    if (accessToken === "") {
    } else {
      setIsAuthorizing(true);
      setTimeout(() => {
        if (firebase.auth().currentUser) {
          var userImpl = firebase.auth().currentUser.multiFactor.user;
          setUser({
            id: userImpl.uid,
            email: userImpl.email,
            name: userImpl.displayName,
            imageUrl: userImpl.photoURL,
            microsoftId: userImpl.providerData[0].uid,
          });
        }
        setIsAuthorizing(false);
      }, 2000);
    }
  }, []);

  const microsoftProvider = (register) => {
    // [START auth_msft_provider_create]
    let provider = new firebase.auth.OAuthProvider("microsoft.com");
    // [END auth_msft_provider_create]

    // [START auth_msft_provider_scopes]
    provider.addScope("mail.send");
    provider.addScope("Files.ReadWrite.All");
    provider.addScope("User.Read");
    //provider.addScope('offline_access');
    // [END auth_msft_provider_scopes]

    // [START auth_msft_provider_params]
    if (register) {
      provider.setCustomParameters({
        redirect_uri: WEB_URL + "set-password",
      });
    } else {
      provider.setCustomParameters({});
    }
    // [END auth_msft_provider_params]

    return provider;
  };

  const msftReauthPopup = () => {
    // [START auth_msft_reauth_popup]
    return new Promise((res, rej) => {
      let provider = microsoftProvider();
      const curUser = firebase.auth().currentUser;
      reauthenticateWithPopup(curUser, provider)
        .then((result) => {
          // accessToken = result.credential.accessToken;
          // store in cookie
          // setCookie("accessToken", accessToken, 1);
          // User is re-authenticated with fresh tokens minted and
          // should be able to perform sensitive operations like account
          // deletion and email or password update.
          // IdP data available in result.additionalUserInfo.profile.
          // OAuth access token can also be retrieved:
          // result.credential.accessToken
          // OAuth ID token can also be retrieved:
          // result.credential.idToken
          res("success");
        })
        .catch((error) => {
          // Handle error.
          rej(error);
        });
    });
    // [END auth_msft_reauth_popup]
  };

  const msftSignInPopup = (provider) => {
    // [START auth_msft_signin_popup]
    setIsAuthorizing(true);
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        //credential = result.credential;

        // OAuth access and id tokens can also be retrieved:
        let accessToken = result.credential.accessToken;
        // store in cookie
        setCookie("accessToken", accessToken, 1);
        const userImpl = firebase.auth().currentUser.multiFactor.user;
        setUser({
          id: userImpl.uid,
          email: userImpl.email,
          name: userImpl.displayName,
          imageUrl: userImpl.photoURL,
          microsoftId: userImpl.providerData[0].uid,
        });
        setIsAuthorizing(false);
      })
      .catch((error) => {
        // Handle error.
        setIsAuthorizing(false);
      });
    // [END auth_msft_signin_popup]
  };

  const setCookie = (cname, cvalue, exhours) => {
    const d = new Date();
    d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const handleAuthClick = useCallback(() => {
    msftSignInPopup(microsoftProvider(false));
    // eslint-disable-next-line
  }, []);

  const handleRegistration = useCallback(() => {
    msftSignInPopup(microsoftProvider(true));
    // eslint-disable-next-line
  }, []);

  const signOut = () => {
    firebaseSignOut(getAuth()).then((res) => {
      setUser(null);
      setIsAuthorizing(false);
    });
  };

  return [
    user,
    isAuthorizing,
    handleAuthClick,
    handleRegistration,
    signOut,
    msftReauthPopup,
  ];
}
